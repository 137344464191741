.App {
  text-align: center;
  font-family: "Arial Black";
  padding-top: 15vh
}
div.title {
  font-size: 20vw;
}
div.subtitle {
  font-size: 3vw;
}
div#crackHider {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 4vw;
  background-color: black;
}